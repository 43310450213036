"use client";
import React, { useCallback } from "react";
import { gaCommonEvent } from "@/src/utils/gtagEvents";
import { isLogin } from "@/src/models/loginModel";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import { useUserInfoContext } from "@/src/provider";

interface IProps {
    index: number;
    type: number;
}
const H5CombinationClient: React.FC<React.PropsWithChildren<IProps>> = ({
    index,
    type,
    children,
}) => {
    const [userInfo] = useUserInfoContext((state) => [state.data]);
    const handleImageGaCommonEvent = useCallback(
        (index: number) => {
            gaCommonEvent(`smallad_${type}_${index + 1}`, {
                event_label: `smallad_${type}_${index + 1}_${
                    userInfo.is_old_customer ? "old" : "new"
                }${isLogin() ? "_login" : ""}`,
            });
        },
        [type, userInfo.is_old_customer],
    );

    return (
        <div
            className={classnames(
                stl["combination-image-container"],
                "combination-image-container",
            )}
            onClick={() => handleImageGaCommonEvent(index)}
        >
            {children}
        </div>
    );
};

export default H5CombinationClient;
