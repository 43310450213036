export interface ITryonProps {
    subscription: number[];
}

export type State = ITryonProps;

export type Action = {
    updateSubscription: (subscriptions: number[]) => void;
};

export type ITryonStateAndAction = State & Action;

export const initialState: State = {
    subscription: [4, 1, 2, 9],
};
