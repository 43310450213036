import React from "react";
import stl from "../styles/frontPage.module.scss";
import { ColorSelectSkeleton } from "@/src/components/SkeletonComponents/ProductSkeleton";
import FmSkeleton from "@/ui-component/basic/FmSkeleton";

export const ProductEmpItem: React.FC = () => {
    return (
        <div className={stl["skeleton-front-page"]}>
            <div className={stl["empty-tag"]}></div>
            <FmSkeleton
                variant="rectangular"
                width={"3.16rem"}
                height={72}
            ></FmSkeleton>
            <div className={stl["color-container"]}>
                <ColorSelectSkeleton num={3} />
                <ColorSelectSkeleton num={1} />
            </div>
            <div className={stl["text-container"]}>
                <FmSkeleton
                    variant="text"
                    width={"30%"}
                    style={{ fontSize: "0.48rem" }}
                ></FmSkeleton>
                <FmSkeleton
                    variant="text"
                    width={"20%"}
                    style={{ fontSize: "0.48rem" }}
                />
            </div>
        </div>
    );
};

const ProductEmp = () => {
    return (
        <div className={stl["skeleton-front-page-container"]}>
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
            <ProductEmpItem />
        </div>
    );
};

export default ProductEmp;
