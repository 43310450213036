import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ITryonStateAndAction, initialState } from "./initialState";

export const useUpdateSubStore = create<ITryonStateAndAction>()(
    devtools(
        // persist(
        (set, get) => ({
            ...initialState,
            updateSubscription(subs) {
                set({ subscription: subs });
            },
        }),
        //   {
        //     name: "try-on-storage",
        //     storage: createJSONStorage(() => sessionStorage),
        //   },
        // ),
        {
            name: "update-subscriptions-store",
        },
    ),
);
