import React, { useCallback, useEffect, useState } from "react";
import { FmDialog, FmLoading, FmToast, IFmDialogProps } from "@/ui-component";
import stl from "../../styles/videoModel.module.scss";
import classnames from "classnames";
interface IProps extends IFmDialogProps {
    src: string;
}
const VideoModel: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(props.open);

    useEffect(() => {
        setLoading(props.open);
    }, [props.open]);
    const onLoad = () => {
        setLoading(false);
    };

    const { onClose, src } = props;
    const handleClose = useCallback(
        (e: any, reason: any) => {
            setLoading(false);
            onClose?.(e, reason);
        },
        [onClose],
    );
    return (
        <>
            <FmToast visible={loading} type={"info"}>
                <FmLoading visible={loading} className={stl["video-loading"]} />
            </FmToast>
            {/* 视频弹窗不改 */}
            <FmDialog
                open={props.open}
                onClose={handleClose}
                contentPosition={"center"}
                paperClassName={classnames(stl["dialog-paper"], {
                    ["hidden"]: loading,
                })}
            >
                <div className={stl["frame-container"]}>
                    {src.indexOf("www.youtube.com") > 0 ? (
                        <iframe
                            src={src}
                            frameBorder="0"
                            style={{
                                width: "100vw",
                                height: "60vw",
                            }}
                            onLoad={onLoad}
                            allow="autoplay; encrypted-media"
                            // allowFullScreen=""
                        />
                    ) : (
                        <div
                            style={{
                                width: "100vw",
                                height: "60vw",
                            }}
                        >
                            <video
                                className="preview-video"
                                autoPlay={true}
                                playsInline
                                muted
                                loop
                                onLoad={onLoad}
                                style={{
                                    width: "100vw",
                                    height: "60vw",
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                            >
                                <source src={src} />
                            </video>
                        </div>
                    )}
                </div>
            </FmDialog>
        </>
    );
};

export default VideoModel;
