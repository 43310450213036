import React, { useCallback, useState } from "react";
import dynamic from "next/dynamic";
import { IDataItem } from "./CustomerShow";
const CustomerShow = dynamic(() => import("./CustomerShow"));
import { FmCarousel, FmImage, FmLink, FmLoading } from "@/ui-component";
import { useTranslation } from "@/src/i18n/client";
import classnames from "classnames";
import stl from "../../styles/components.module.scss";
interface IProps {
    type: string;
    showData: IDataItem[][];
    loading: boolean;
}

export const typeParse: any = {
    1: 9,
    2: 6,
    3: 4,
};

export function getNewArrayByPerPage(totalNum: IDataItem[], perPage: number) {
    const copyNum = totalNum.slice(0); // 复制一份
    const returnArr = [];
    const totalPage = Math.ceil(totalNum.length / perPage); // 求总页码，向下取整
    let index = 1; // 第一页
    while (totalPage >= index) {
        returnArr.push(copyNum.splice(0, perPage)); // copy参数不用管，因为splice会改变数组
        index++; // 页码+1
    }

    return returnArr;
}

const UserImageShow: React.FC<IProps> = (props) => {
    const { type, showData, loading } = props;
    const { t } = useTranslation("common");
    const [modalShow, setModalShow] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0); // pageIndex对应carousel的用户秀的第index页

    // item: 获取点击第index页的data中的item项，显示也是显示item项；如果到index页的最后一项，则index+1，获取第index+1页的数据
    const renderImageItem = useCallback(
        (item: IDataItem, pageIndex: number, key: number) => {
            if (!item) {
                return;
            }
            const { reviews_photo } = item;
            // 确认实际index是第几个
            const actualyIndex = pageIndex * typeParse[type] + key;
            return (
                <div
                    key={"renderItem-" + key}
                    className={classnames(stl["user-cms-show-img"])}
                    onClick={(e: any) => {
                        e.preventDefault();
                        setCarouselIndex(actualyIndex);
                        setModalShow(true);
                    }}
                >
                    <FmImage
                        image={
                            "https://df5apg8r0m634.cloudfront.net/" +
                            reviews_photo
                        }
                    />
                </div>
            );
        },
        [type],
    );

    const renderType = useCallback(
        (perPageData: IDataItem[], pageIndex: number) => {
            return (
                <div
                    className={classnames(
                        stl["render-image-container"],
                        stl["render-image-container-" + type],
                    )}
                    key={pageIndex}
                >
                    {perPageData.map((item: IDataItem, index: number) => (
                        <div
                            key={index}
                            className={classnames(
                                stl["image-content"],
                                stl[`render-image-by-type${index}`],
                                `render-image-by-type${index}`,
                            )}
                        >
                            {renderImageItem(item, pageIndex, index)}
                        </div>
                    ))}
                </div>
            );
        },
        [renderImageItem, type],
    );

    return (
        <>
            {showData.length < 1 ? null : (
                <div className={stl["user-image-show-container"]}>
                    <div
                        className={classnames(
                            "default_layout",
                            stl["user-image-show-link"],
                        )}
                    >
                        <FmLink
                            className={stl["view-more-link"]}
                            href="/z/customershowpage.html"
                        >
                            {t("common:View_More")}
                        </FmLink>
                    </div>
                    <div
                        className={classnames(
                            "default_layout",
                            stl["loading-content"],
                            {
                                ["hidden"]: !loading,
                            },
                        )}
                    >
                        <FmLoading visible={loading} />
                    </div>
                    <FmCarousel
                        autoplay
                        interval={5000}
                        infinite
                        dots={showData.length > 1}
                        carouselClasses={{
                            container: classnames(
                                stl["user-iamge-show-carousel-container"],
                                "user-iamge-show-carousel-container",
                            ),
                            dots: classnames(
                                stl["user-image-show-carousel-dots"],
                                "user-image-show-carousel-dots",
                            ),
                        }}
                    >
                        {showData.map((item, index) => renderType(item, index))}
                    </FmCarousel>
                    <CustomerShow
                        hideViewMore={false}
                        data={showData.flat()}
                        show={modalShow}
                        showPageIndex={carouselIndex}
                        onClose={() => setModalShow(false)}
                        onChangeIndex={(index) => setCarouselIndex(index)}
                    />
                </div>
            )}
        </>
    );
};

export default UserImageShow;
