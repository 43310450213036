"use client";
import React, { useState } from "react";
import stl from "../../styles/components.module.scss";
import VideoModel from "./VideoModel";
import { IProps } from "./VideoItem";

const VideoItemClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div
                className={stl["video-guides-item"]}
                onClick={(e) => setShowModal(true)}
            >
                {props.children}
            </div>
            <VideoModel
                open={showModal}
                src={props.data.video_src || props.data.url}
                onClose={() => setShowModal(false)}
            />
        </>
    );
};

export default VideoItemClient;
