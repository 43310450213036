import { CSSProperties, ReactNode } from "react";
/**
 * 公共接口列表
 */

export interface IStyleProps {
    className?: string;
    style?: CSSProperties;
}

export interface PageParamsProps {
    // 动态路由参数
    params: Record<string, string | string[]>;
    // search参数
    searchParams: Record<string, string | string[]>;
}

export type ExtractT1KeyInT2Props<T1, T2> = Extract<keyof T1, keyof T2>; // 提取出 T1里与T2共有的属性接口
export type ExcludeT1KeyInT2Props<T1, T2> = Exclude<keyof T1, keyof T2>; // 过滤T1里在T2中存在的接口
export type RecordProps<T1 extends keyof T2, T2> = {
    [K in T1]: T2[K];
};

// 键值重映射
export type AddPreFixProps<T1, T2 extends string> = {
    [P in keyof T1 & string as `${T2}${Capitalize<P>}`]?: T1[P];
};

//  将T1 与 T2重合的部分剔除，并加工成有T3前缀的新街口名称，继承原接口属性
export type ExtractT2FrT1<T1, T2, T3 extends string> = AddPreFixProps<
    RecordProps<ExtractT1KeyInT2Props<T1, T2>, T1>,
    T3
>;

/**
 * 将T1 与 T2 重合的部分剔除得到【T1除重合部分以外】的接口
 * */
export type ExcludeT1FrT2<T1, T2> = RecordProps<
    ExcludeT1KeyInT2Props<T1, T2>,
    T1
>;

// 重置接口为never
export type NeverT<T extends string> = {
    [P in T]: never;
};

type NeverRecord<T> = {
    [P in keyof T]: never;
};
/**
 * 传入interface接口:
 * interface I1 {
 *  key: string;
 *  value: string;
 * }
 * interface I2 {
 *  key: string;
 *  icon: string;
 * }
 * => icon和value不能同时共存
 *
 */
export type NeverInterface<T1, T2> =
    | (T1 & T2)
    | (NeverRecord<T1> & NeverRecord<T2>);
/**
 * common-interface: 提供方法可以直接拿到已经处理好的interface
 * 加工T1, T2, T3 得到一份T2与T1属性重复且重复属性以T3作为前缀被重命名的属性
 * 缺点： T2只能一次处理一个props, 如果是一个props继承多个props，需要分多次处理
 */
export type WholePropsIncludeAddPreFixAndOtherProps<
    T1,
    T2,
    T3 extends string,
> = ExtractT2FrT1<T1, T2, T3> & ExcludeT1FrT2<T1, T2> & ExcludeT1FrT2<T2, T1>;

export interface IChildren {
    children: ReactNode[];
}

// 设备类型: pc & mobile
export enum DeviceType {
    PC = 1,
    MOBILE = 2,
}
