import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FmButton, FmCheckbox } from "@/ui-component";
import stl from "./GetCodeForm.module.scss";
import classnames from "classnames";
import { useTranslation } from "@/src/i18n/client";

interface IProps {
    onClose: () => void;
    updateSub: (array: number[]) => void;
    subscription: number[];
}
const UpdateForm: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["common", "account"]);
    const { updateSub, onClose } = props;
    const [subscription, setSubscription] = useState<number[]>(
        props.subscription,
    );
    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.subscription]);
    const handleSubmit = useCallback(() => {
        updateSub(subscription);
        onClose();
    }, [onClose, subscription, updateSub]);

    const dataSource = useMemo(() => {
        return [4, 2, 3, 5, 1, 9, 7, 6].map((item) => {
            return {
                label: item + t("account:Newsletters.sel" + item),
                value: item,
                formControlLabelClassName: classnames(
                    stl["label-content"],
                    "label-content",
                ), // 给整个label
            };
        });
    }, [t]);
    return (
        <>
            <FmCheckbox
                dataSource={dataSource}
                values={subscription}
                onChange={(val) => {
                    setSubscription(val as number[]);
                }}
                className={classnames(
                    stl["checkbox-container"],
                    "checkbox-container",
                )}
            />
            <div className={stl["checkbox-down-button"]}>
                <FmButton
                    className={stl["update-subscription"]}
                    onClick={handleSubmit}
                >
                    {t("common:btn.upDate")}
                </FmButton>
            </div>
        </>
    );
};

export default UpdateForm;
