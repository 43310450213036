"use client";
import React, { useEffect } from "react";
import criteoAd from "@/src/components/LoadAnalysis/analysis/criteo";
import useAnalysis from "@/src/hooks/useAnalysis";

// 需要在客户端进行初始化的使用这个组件
const HomePageInit: React.FC = () => {
    const { GoogleAnalytics } = useAnalysis();

    useEffect(() => {
        criteoAd.viewHome();
        GoogleAnalytics({
            ecomm_prodid: "",
            ecomm_pagetype: "home",
            ecomm_totalvalue: 0,
        });
    }, [GoogleAnalytics]);

    return null;
};

export default HomePageInit;
