"use client";
import React, { useRef, useState } from "react";
import stl from "../styles/components.module.scss";
import classnames from "classnames";

interface IProps {
    style?: any;
    loop?: boolean;
    autoPlay?: boolean;
    url: string;
}

const Video: React.FC<IProps> = (props) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { autoPlay = false, loop = false, style = {}, url } = props;
    const [status, setStatus] = useState(false);

    const playVideo = () => {
        if (autoPlay) {
            return;
        }
        if (status) {
            videoRef.current?.pause();
        } else {
            videoRef.current?.play();
        }

        setStatus((prev) => !prev);
    };

    return (
        <div className={stl["preview-video"]}>
            <video
                className={stl["preview-video-content"]}
                autoPlay={autoPlay}
                playsInline
                muted
                ref={videoRef}
                loop={loop}
                style={style}
                onContextMenu={(e) => e.preventDefault()}
            >
                <source src={url} />
            </video>
            <div className={stl["video-mask"]} onClick={playVideo}>
                {!status && !autoPlay && (
                    <span
                        className={classnames(
                            "glyphicon",
                            "glyphicon-play",
                            stl["video-icon"],
                        )}
                    ></span>
                )}
            </div>
        </div>
    );
};

export default Video;
