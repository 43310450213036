"use client";
import React from "react";
import { gaIndexCategoryEntranceClick } from "@/src/utils/gtagEvents";
import { isLogin } from "@/src/models/loginModel";
import stl from "../../styles/h5bubble.module.scss";
import { useUserInfoContext } from "@/src/provider";

interface IProps {
    idx: number;
}
const H5BubbleClient: React.FC<React.PropsWithChildren<IProps>> = ({
    idx,
    children,
}) => {
    const [userInfo] = useUserInfoContext((state) => [state.data]);
    return (
        <div
            className={stl["category_item-image-container"]}
            onClick={() => {
                gaIndexCategoryEntranceClick(idx + 1, {
                    event_label: `entrance${idx + 1}_${
                        userInfo.is_old_customer ? "old" : "new"
                    }${isLogin() ? "_login" : ""}`,
                });
            }}
        >
            {children}
        </div>
    );
};
export default H5BubbleClient;
