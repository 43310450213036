import { DeviceType } from "@/src/app/interface";
import { HandleJavaUsualFetch } from "@/src/utils/fetch";

export interface IHomeProductsParams {
    id?: number;
    deviceType?: DeviceType;
}
export function getHomeProducts(params: IHomeProductsParams) {
    const defaultParams = {
        deviceType: DeviceType.MOBILE, // 移动端重构默认是手机
    };
    return HandleJavaUsualFetch.get(`/cms/pageGoodsData`, {
        ...defaultParams,
        ...params,
    });
}

export async function getCMSDataClient(params: any) {
    const defaultQuery = {
        pageType: params.pageType ?? "home",
        deviceType: DeviceType.MOBILE,
    };

    return HandleJavaUsualFetch.get("/cms/pageData", {
        ...defaultQuery,
        // childPageId: params.sublink,
        // childProductId: params.childProductId,
    });
}
