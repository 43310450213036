"use client";
import React, { useCallback } from "react";
import { gaIndexBannerClick } from "@/src/utils/gtagEvents";
import { isLogin } from "@/src/models/loginModel";
import { IStyleProps } from "@/src/app/interface";
import { useUserInfoContext } from "@/src/provider";

interface IImageContent extends IStyleProps {
    gaIndex: number;
}
const ImageContentClient: React.FC<React.PropsWithChildren<IImageContent>> = (
    props,
) => {
    const { is_old_customer } = useUserInfoContext((state) => state.data);
    const handleGaIndexBannerClick = useCallback(
        (gaIndex: number) => {
            gaIndexBannerClick(gaIndex, {
                event_label: `banner${gaIndex}_${
                    is_old_customer ? "old" : "new"
                }${isLogin() ? "_login" : ""}`,
            });
        },
        [is_old_customer],
    );
    return (
        <div
            className={props.className}
            style={props.style}
            onClick={() => handleGaIndexBannerClick(props.gaIndex)}
        >
            {props.children}
        </div>
    );
};

export default ImageContentClient;
