/*
 *
 * 首页商品展示
 */
"use client";
import React, { useState, useRef, useMemo, useCallback } from "react";
import {
    getCookieItem,
    jointImg,
    salePercent,
    showPrice,
} from "@/lib/commonService";

import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import {
    getDiscountText,
    productClick,
    flowSpcice,
    AddFlow,
    gaCommonEvent,
} from "@/src/utils";
import { FmButton, FmIcon, FmImage, FmLink, FmTag } from "@/ui-component";
import { useCommonContext, useRateContext } from "@/src/provider";
import { account, signInModal, tryOn } from "@/store";
import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import stl from "../../styles/frontPage.module.scss";
import classnames from "classnames";
import { IProductInfoAboutDimensionsAndProducts } from "@/src/app/specsubs/[id]/type";
import { ProductEmpItem } from "../ProductEmp";
import GlassColor from "@/src/components/ColorSelect/GlassColor";
import reddit from "@/src/components/LoadAnalysis/analysis/reddit";
import variable from "@/src/styles/variables.module.scss";
import { useTranslation } from "@/src/i18n/client";
import ColorSelect from "@/src/components/ColorSelect";
import MyFitTag from "@/src/components/MyFitTag";
import {
    addFavouriteClickEvent,
    addProductsSkuClickEvent,
} from "@/src/models/emailData";
import { isLogin } from "@/src/models/loginModel";

interface IProps extends Required<IProductInfoAboutDimensionsAndProducts> {}
const HomeProductItem: React.FC<IProps> = (props) => {
    const { site } = useCommonContext();
    const { t } = useTranslation("common");

    const [getPrice] = useRateContext((state) => [state.getPrice]);

    const [activeColor, setActiveColor] = useState(init(props));
    const loadingRef = useRef(false);

    const [openModal] = signInModal.useSignInModalStore((state) => [
        state.openModal,
    ]);

    const [clickButtonToOpenTryOn] = tryOn.useTryonStore((state) => [
        state.clickButtonToOpenTryOn,
    ]);

    const [addFavorite, favoriteArr] = account.useAccountStore((state) => [
        state.addFavorite,
        state.favoriteArr,
    ]);
    function init(props: any) {
        let activeColorObj = props.products_color_relation[0];

        const showprice = activeColorObj
            ? showPrice(activeColorObj.product_price)
            : {};
        activeColorObj = { ...activeColorObj, ...showprice };

        if (!activeColorObj.color_id) {
            activeColorObj = props.products_color_relation[0];
        }

        return activeColorObj;
    }

    //收藏
    const handleAddFavorite = useCallback(
        async (productId: number, colors_id: number, isLogin: boolean) => {
            if (loadingRef.current) return;
            loadingRef.current = true;
            try {
                addFavorite(
                    {
                        productId,
                        colorsId: colors_id,
                        activeColor,
                    },
                    () => {
                        loadingRef.current = false;
                    },
                );
            } catch (e) {
                loadingRef.current = false;
            }
        },
        [activeColor, addFavorite],
    );

    const onChange = (value: number) => {
        const obj = props.products_color_relation.filter(
            (item) => item.color_id === value,
        )[0];
        if (obj?.products_id && obj?.color_id) {
            acquisition.clickProduct(
                "clickProduct",
                `${obj.products_id}-${obj.color_id}`,
            );
            gaCommonEvent("new_specsubs_click_product");
        }
        addProductsSkuClickEvent({
            colorId: obj.color_id,
            price: obj.product_price?.discount_price,
        });
        const showprice = showPrice(obj.product_price);
        setActiveColor({ ...obj, ...showprice });
    };

    //点击商品
    const handleClickProduct = (props: any) => {
        if (props.products_id && activeColor.color_id) {
            acquisition.clickProduct(
                "clickProduct",
                `${props.products_id}-${activeColor.color_id}`,
            );
        }
        AddFlow.ProductClick(props.products_id);
        flowSpcice.ProductClick(props.products_id);
        addFavouriteClickEvent({
            colorId: activeColor.color_id,
            price: activeColor.price,
        });
        reddit.AddToWishlist({
            currency: getCookieItem(CURRENCY_COOKIE_NAME),
            itemCount: 1,
            value: activeColor.price,
        });
        //GA统计点击事件
        productClick({
            currency: getCookieItem(CURRENCY_COOKIE_NAME),
            value: activeColor.price,
            items: [
                {
                    id: props.products_id,
                    name: props.products_model,
                    item_id: props.products_id,
                    item_name: props.products_model,
                },
            ],
        });
        // 重构项目埋点，跟老项目gaCommonEvent('old_specsubs_click_product');对应
        gaCommonEvent("new_specsubs_click_product");
    };

    const imgShow: string = useMemo<string>(
        () =>
            jointImg({
                productId: props.products_id,
                size: "middle",
                imgTotal: 2,
                name: activeColor.color_image,
            }),
        [activeColor.color_image, props.products_id],
    );

    const { showDiscount } = activeColor;

    const salePer = useMemo(() => {
        return activeColor.discount_price
            ? salePercent(activeColor.price, activeColor.discount_price)
            : 0;
    }, [activeColor.discount_price, activeColor.price]);
    const discountText = useMemo(() => {
        return getDiscountText(site, salePer, t);
    }, [salePer, site, t]);

    const favoriteIcon = useMemo(() => {
        return favoriteArr.includes(activeColor.color_id)
            ? "icon--full-heart"
            : "icon--empty-heart";
    }, [activeColor.color_id, favoriteArr]);

    const handleFavorite = useCallback(() => {
        isLogin()
            ? handleAddFavorite(props.products_id, activeColor.color_id, true)
            : openModal(async () => {
                  handleAddFavorite(
                      props.products_id,
                      activeColor.color_id,
                      false,
                  );
              });
    }, [activeColor.color_id, handleAddFavorite, openModal, props.products_id]);

    return !activeColor.color_id ? (
        <ProductEmpItem></ProductEmpItem>
    ) : (
        <div className={stl["home-product-item"]}>
            <div className={stl["home-product-icons-box"]}>
                <div className={stl["tag-container"]}>
                    {showDiscount && salePer >= 0 && (
                        <FmTag backgroundColor="#EE5454">{discountText}</FmTag>
                    )}
                    <MyFitTag
                        lensWidth={props.dimensions_lens}
                        bridgeWidth={props.dimensions_bridge}
                    />
                </div>
                <FmIcon
                    icon={favoriteIcon}
                    className={stl["favorite-icon-btn"]}
                    onClick={handleFavorite}
                />
            </div>
            <div className={stl["home-product-item-container"]}>
                {/* 商品图片 */}
                <div
                    className={stl["home-product-item-img"]}
                    onClick={() => handleClickProduct(props)}
                >
                    {activeColor.color_id && (
                        <FmLink
                            href={`/productDetail/${props.products_id}?color=${activeColor.color_id}&master_categories_id=${props.master_categories_id}`}
                            target="_blank"
                        >
                            <FmImage
                                image={imgShow}
                                lazyLoadingAnimation
                                height={250}
                                alt={props.products_model}
                            />
                        </FmLink>
                    )}
                </div>
                <ColorSelect
                    onChange={onChange}
                    selected={activeColor.color_id}
                    rowCenter={true}
                    showSize={3}
                    className={stl["color-select-wrapper"]}
                    popoverClassName={{
                        wrapper: stl["glass-hidden-wrapper"],
                        container: stl["glass-hidden-container"],
                    }}
                >
                    {props.products_color_relation.map((item, index) => (
                        <GlassColor
                            key={index}
                            color_id={item.color_id}
                            color_name={item.color_name}
                        />
                    ))}
                </ColorSelect>
                <div
                    className={classnames(
                        stl["line"],
                        stl["home-product-price"],
                    )}
                >
                    <p className={stl["price"]}>
                        {activeColor.showDiscount && (
                            <s
                                dangerouslySetInnerHTML={{
                                    __html: getPrice(
                                        activeColor.product_price.price,
                                    ),
                                }}
                                className={classnames({
                                    [stl["top-s"]]:
                                        getPrice(activeColor.price).length > 7,
                                })}
                            ></s>
                        )}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getPrice(activeColor.price),
                            }}
                        ></span>
                    </p>
                    <FmButton
                        onClick={() =>
                            clickButtonToOpenTryOn(activeColor.color_id, props)
                        }
                        variant={"text"}
                    >
                        {t("common:TRYON")}
                    </FmButton>
                </div>
            </div>
        </div>
    );
};

export default HomeProductItem;
