/*
 * 首页商品展示列表
 */
"use client";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { productShow, getProductItemParams, parse_url } from "@/src/utils";
import { getHomeProducts, IHomeProductsParams } from "@/src/models/cmsClient";
import { indexProducts } from "@/src/models/loginModel";
import { H5Product } from "@/store/modules/pageLayout";
import { FmLink, FmListItem, FmTabs, FmViewList } from "@/ui-component";
import stl from "../../styles/frontPage.module.scss";
import HomeProductItem from "./HomeProductItem";
import { useTranslation } from "@/src/i18n/client";
import ProductEmp from "../ProductEmp";
import classnames from "classnames";
import CompTryOn from "@/src/components/CompTryOn";
import { tryOn } from "@/store";
import { DeviceType } from "@/src/app/interface";

interface IProps {
    H5Product: H5Product;
}
const ProductPanel: React.FC<IProps> = (props) => {
    const { H5Product } = props;

    const [initialPage, setInitialPage] = useState(0);
    const { t } = useTranslation("common");
    const closeTryOn = tryOn.useTryonStore((state) => state.closeTryOn);
    const [products, setProducts] = useState({
        // 对应的产品要在客户端请求，不要问为什么！
        news: [],
        hot: [],
    });

    const googleAnalytics = useCallback(() => {
        const { hot, news } = products;

        setTimeout(() => {
            productShow({
                item_list_id: "home hot products",
                item_list_name: "home hot products",
                items: getProductItemParams({ productList: hot }),
            });
            productShow({
                item_list_id: "home news products",
                item_list_name: "home news products",
                items: getProductItemParams({ productList: news }),
            });
        }, 0);
    }, [products]);

    useEffect(() => {
        // product在useEffect里更新，会执行googleAnalytics
        googleAnalytics();
    }, [googleAnalytics, products]);

    useEffect(() => {
        let params: IHomeProductsParams = {
            deviceType: DeviceType.MOBILE,
        };
        if (/preview_cms/.test(location.search)) {
            params.id = parse_url(location.href)?.["preview_cms"];
        }
        getHomeProducts(params)
            .then((arg: any) => {
                if (
                    !(arg["hot"] || arg["news"]) ||
                    (arg["hot"].length <= 0 && arg["news"].length <= 0)
                ) {
                    getPreData();
                    return;
                }

                setProducts(arg);
            })
            .catch(() => {
                getPreData();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //数据兼容
    const getPreData = () => {
        indexProducts({ limit: 8, type: true }).then((arg: any) => {
            setProducts(arg);
        });
    };

    const onTabClick = (e: any, val: number): void => {
        setInitialPage(val);
    };

    const { order = 1, showbest = true, shownew = true } = H5Product;
    //数据初始化
    const initTabsData: any = useMemo(
        () => [
            {
                id: 0,
                title: H5Product.new.title,
                val: "news",
                products: products["news"], // 这块用的不是cms的数据，是后面客户端请求的
                isShow: shownew,
                href: H5Product.new.shopAll,
            },
            {
                id: 1,
                title: H5Product.best.title,
                val: "hot",
                isShow: showbest,
                products: products["hot"], // 这块用的不是cms的数据，是后面客户端请求的
                href: H5Product.best.shopAll,
            },
        ],
        [
            H5Product.best.shopAll,
            H5Product.best.title,
            H5Product.new.shopAll,
            H5Product.new.title,
            products,
            showbest,
            shownew,
        ],
    );

    const renderRow = (data: any) => {
        return data.map((props: any, index: number) => {
            return (
                <FmListItem className={stl["double-list"]} key={index}>
                    {props.map((item: any, key: number) => (
                        <HomeProductItem {...item} key={key} />
                    ))}
                </FmListItem>
            );
        });
    };

    const tabsData = useMemo(() => {
        //隐藏的数据不展示
        const tempTabsData = initTabsData.filter((item: any) => item.isShow);

        if (tempTabsData.length < 1) {
            return null;
        }
        //排序
        if (order != 1) {
            tempTabsData.reverse();
        }
        //重置id
        return tempTabsData.map((item: any) => {
            const data = item.products.reduce(
                (result: any[], product: any, index: number) => {
                    if (index % 2 === 0) {
                        const chunk: any = item.products.slice(
                            index,
                            index + 2,
                        );
                        chunk.id = `${product.products_id}-${Math.random()}`;
                        result.push(chunk);
                    }
                    return result;
                },
                [],
            );
            return {
                value: item.val,
                label: item.title,
                className: stl["tab-bar"],
                classes: {
                    root: stl["tab-root"],
                    selected: stl["tab-selected"],
                    indicator: stl["tab-indicator"],
                },
                children: (
                    <>
                        <FmViewList
                            className={stl["viewlist-container"]}
                            currentPage={1}
                            total={data.length}
                            pageSize={data.length}
                            noDataTips={<ProductEmp />}
                            hasListItem
                        >
                            {/* 取代renderrow */}
                            {renderRow(data)}
                        </FmViewList>
                        <FmLink
                            href={item.href}
                            className={classnames(stl["view-all"], "view-all")}
                            icon={"icon--arrow-right"}
                        >
                            {t("common:ShopAll")}
                        </FmLink>
                    </>
                ),
            };
        });
    }, [initTabsData, order, t]);
    return (
        <div className={stl["home-product-panel"]}>
            <FmTabs
                variant={"fullWidth"}
                tabPanelClassName={stl["tab-panel-content"]}
                tabs={tabsData}
                value={initialPage}
                onChange={onTabClick}
                tabsClassName={classnames(
                    stl["tab-bar-container"],
                    "tab-bar-container",
                )}
                classes={{
                    indicator: stl["tab-indicator"],
                }}
                carousel
            />
            <CompTryOn onClose={closeTryOn} />
        </div>
    );
};

export default ProductPanel;
